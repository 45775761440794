import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";
import {
  Border1,
  Border2,
  Border3,
  Border4,
  Border5,
  Border6,
  FrameBody,
  FrameBorderView,
  FrameDes1,
  FrameImg,
  FrameItem,
  FrameNow,
  FrameOver,
  FrameSlide,
  FrameTpe,
  FrameVideo,
  ImgBg1,
  ImgBg2,
  TxtA,
  TxtDes1,
  TxtDes2,
  TxtNow,
  TxtPro,
  TxtTitle,
  Wrapper,
} from "../styles";
import IcStar from "../../src/assets/icons/ic_star.png";
import IcElectric from "../../src/assets/icons/ic_electric.png";
import IcDemo from "../../src/assets/images/ic_demo.png";
import Bg1 from "../../src/assets/images/bg_1.png";
import Bg2 from "../../src/assets/images/bg_2.png";
import Img1 from "../../src/assets/images/img_1.png";
import Img2 from "../../src/assets/images/img_2.png";
import Img3 from "../../src/assets/images/img_3.png";
import "../App.css";
import {
  EventName,
  useFirebaseTracking,
} from "../hooks/firebase/useFirebaseTracking";
import Slider from "react-slick";

const Imgs = [
  { id: 0, image: Img1 },
  { id: 1, image: Img2 },
  { id: 2, image: Img3 },
  { id: 3, image: Img1 },
  { id: 4, image: Img2 },
  { id: 5, image: Img3 },
];

function App() {
  const { onEventTracking } = useFirebaseTracking();
  const [active, setActive] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 2000,
    variableWidth: true,
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", urlParams);

    const adjCampaign = urlParams.get("adj_campaign") || "";
    const adjAdgroup = urlParams.get("adj_adgroup") || "";
    const adjCreative = urlParams.get("adj_creative") || "";
    const fbclid = urlParams.get("fbclid") || "";
    const fbpid = urlParams.get("fbpid") || "";

    const timer = setTimeout(() => {
      const redirectUrl = `https://app.adjust.com/1l5elvon?campaign=${adjCampaign}&adgroup=${adjAdgroup}&creative=${adjCreative}&fb_pixel_id=965644328880230&fbclid=${fbclid}&fbpid=${fbpid}`;
      console.log("redirectUrl", redirectUrl);

      window.location.href = redirectUrl;
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const onClickCta = () => {
    onEventTracking(EventName.CLICK_CTA);
    window.open(
      "https://apps.apple.com/us/app/ai-artimind-ai-art-generator/id6459831969?ppid=9f821e48-750a-4589-84d7-362a372b8f1c",
      "_blank"
    );
  };

  const onSwipeHandle = (oldIndex: any, newIndex: any) => {
    setActive(newIndex);
  };

  const renderView = () => (
    <FrameBorderView>
      <Border1>
        <Border2>
          <Border3>
            <ImgBg1 src={Bg1} />
            <ImgBg2 src={Bg2} />
            <Border4>
              <Border5>
                <Border6></Border6>
              </Border5>
            </Border4>
          </Border3>
        </Border2>
      </Border1>
      <FrameSlide>
        <Slider autoplay={true} {...settings} beforeChange={onSwipeHandle}>
          {Imgs.map((img: any, index) => (
            <FrameItem key={index}>
              <FrameImg
                src={img.image}
                style={
                  active === img.id ? {} : { border: "1px solid transparent" }
                }
              />
            </FrameItem>
          ))}
        </Slider>
        <FrameOver />
      </FrameSlide>
    </FrameBorderView>
  );

  return (
    <Wrapper>
      <FrameBody>
        {renderView()}
        <TxtTitle>artimind</TxtTitle>
        <FrameTpe className={"border-item"}>
          <img src={IcStar} height={21} width={21} />
          <TxtPro>PRO</TxtPro>
        </FrameTpe>
        <TxtDes1>
          <img
            src={IcElectric}
            height={20}
            width={20}
            style={{ marginTop: 3 }}
          />
          Look Your Best at Every Stage of Life
          <img
            src={IcElectric}
            height={20}
            width={20}
            style={{ marginTop: 3 }}
          />
        </TxtDes1>
        <TxtDes2>
          <img
            src={IcElectric}
            height={20}
            width={20}
            style={{ marginTop: 3 }}
          />
          <FrameDes1>
            Effortlessly create photos that celebrate your experience, style,
            and confidence with AI
          </FrameDes1>
          <img
            src={IcElectric}
            height={20}
            width={20}
            style={{ marginTop: 3 }}
          />
        </TxtDes2>
        <TxtA className={"animated-text"}>Professional AI Headshots</TxtA>
        <FrameNow onClick={onClickCta}>
          <img src={IcStar} height={24} width={24} />
          <TxtNow>Discover now</TxtNow>
        </FrameNow>
      </FrameBody>
    </Wrapper>
  );
}

export default App;
